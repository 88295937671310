import { compressToBase64, decompressFromBase64 } from "lz-string";
import brotliPromise from "brotli-wasm";

export default class EncodeUtil {
  static async compressRouteQuery(value: string) {
    const brotli = await brotliPromise;
    const textEncoder = new TextEncoder();

    const uncompressedData = textEncoder.encode(value);
    const compressedDataFromBrotli = brotli.compress(uncompressedData);

    return compressToBase64(compressedDataFromBrotli.toString());
  }

  static async decompressRouteQuery(value: string) {
    const brotli = await brotliPromise;
    const textDecoder = new TextDecoder();

    const base64Decomp = decompressFromBase64(value);
    const decompressedData = brotli.decompress(
      new Uint8Array(base64Decomp.split(",") as any)
    );

    return textDecoder.decode(decompressedData);
  }
}
